import React from 'react';
import ReactSelect, { ClassNamesConfig, StylesConfig, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FormFieldWrapper from '../FormFieldWrapper';
import * as Styled from './styled';

import { Controller, Control, FieldError, UseFormRegister } from 'react-hook-form';

interface SelectProps {
  selectedValue?: string;
  options: any[];
  customComponents?: any;
  label: string;
  name: string;
  prefixElement?: React.ReactNode;
  isSearchable?: boolean;
  isCreateable?: boolean;
  isClearable?: boolean;
  menuIsOpen?: boolean;
  placeholder?: string;
  error?: FieldError;
  success?: boolean;
  disabled?: boolean;
  wide?: boolean;
  hint?: string;
  control?: Control<any, any>;
  classNames?: ClassNamesConfig<any>;
  styles?: StylesConfig<any>;
  style?: React.CSSProperties;
  onChange?: (value: string) => void;
}

const Select: React.FC<SelectProps> = ({
  selectedValue,
  options,
  customComponents,
  prefixElement,
  isSearchable = true,
  isCreateable = false,
  isClearable = false,
  menuIsOpen,
  name,
  label,
  error,
  success,
  disabled,
  hint,
  onChange,
  control,
  classNames,
  styles,
  placeholder,
  ...props
}) => {
  // const value = options && options.filter((opt) => opt.value === selectedValue)[0];
  return (
    <FormFieldWrapper error={error} label={label} hint={hint} success={success} {...props}>
      {!!control ? (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <SelectControl
                error={error}
                selectedValue={value}
                options={options}
                customComponents={customComponents}
                prefixElement={prefixElement}
                isSearchable={isSearchable}
                isCreateable={isCreateable}
                isClearable={isClearable}
                menuIsOpen={menuIsOpen}
                name={name}
                success={success}
                onChange={(option) => {
                  console.log('OnChange', option);
                  onChange(option || '');
                }}
                styles={styles}
                classNames={classNames}
                placeholder={placeholder}
                disabled={disabled}
                // onBlur={onBlur}
                // value={options.filter((option) => value?.includes(option.value))}
                // defaultValue={options.filter((option) => value?.includes(option.value))}
              />
            );
          }}
        />
      ) : (
        <SelectControl
          error={error}
          selectedValue={selectedValue}
          options={options}
          customComponents={customComponents}
          prefixElement={prefixElement}
          isSearchable={isSearchable}
          isCreateable={isCreateable}
          isClearable={isClearable}
          name={name}
          success={success}
          onChange={(option) => onChange?.(option)}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
      )}
    </FormFieldWrapper>
  );
};

interface SelectControlProps {
  selectedValue?: string;
  options: any[];
  customComponents?: any;
  getOptionLabel?: any;
  name: string;
  prefixElement?: React.ReactNode;
  isSearchable?: boolean;
  menuIsOpen?: boolean;
  isCreateable?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  error?: FieldError;
  success?: boolean;
  disabled?: boolean;
  ref?: any;
  wide?: boolean;
  classNames?: ClassNamesConfig<any>;
  styles?: StylesConfig<any>;
  onChange: (value: string) => void;
}

export const SelectControl: React.FC<SelectControlProps> = ({
  selectedValue,
  options,
  customComponents,
  // getOptionLabel,
  prefixElement,
  isSearchable = true,
  menuIsOpen,
  isCreateable,
  isClearable,
  name,
  error,
  success,
  disabled,
  onChange,
  placeholder,
  ref,
  wide,
  classNames,
  styles,
  ...props
}) => {
  const value = options.find((option) => option.value === selectedValue) || '';
  return (
    <Styled.Select error={error} success={success} $wide={wide}>
      {isCreateable ? (
        <CreatableSelect
          className="oxen-select"
          classNamePrefix="oxen-select"
          // value={value}
          isSearchable={isSearchable}
          isDisabled={disabled}
          isOptionDisabled={(option) => option.disabled}
          isClearable={isClearable}
          options={options}
          menuIsOpen={menuIsOpen}
          name={name}
          placeholder={placeholder}
          instanceId={name}
          onChange={(selected) => {
            onChange(selected?.value);
          }}
          classNames={classNames}
          styles={styles}
          components={{
            ValueContainer: ({ children, ...props }) => {
              return (
                <components.ValueContainer {...props}>
                  <Styled.ValueWrapper>
                    {prefixElement}
                    {children}
                  </Styled.ValueWrapper>
                </components.ValueContainer>
              );
            },
            IndicatorSeparator: () => null,
            ...customComponents,
          }}
          {...props}
          value={value}
        />
      ) : (
        <ReactSelect
          className="oxen-select"
          classNamePrefix="oxen-select"
          // value={value}
          isSearchable={isSearchable}
          isClearable={isClearable}
          isDisabled={disabled}
          isOptionDisabled={(option) => option.disabled}
          options={options}
          menuIsOpen={menuIsOpen}
          name={name}
          placeholder={placeholder}
          instanceId={name}
          onChange={(selected) => {
            onChange(selected?.value);
          }}
          classNames={classNames}
          styles={styles}
          components={{
            ValueContainer: ({ children, ...props }) => {
              return (
                <components.ValueContainer {...props}>
                  <Styled.ValueWrapper>
                    {prefixElement}
                    {children}
                  </Styled.ValueWrapper>
                </components.ValueContainer>
              );
            },
            IndicatorSeparator: () => null,
            ...customComponents,
          }}
          {...props}
          value={value}
        />
      )}
    </Styled.Select>
  );
};

export default Select;
